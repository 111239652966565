<script lang="ts" setup>
import type {
  strapiNavigationItem,
  strapiOptions,
} from '~/interfaces/strapi.interface'
import AkImage from '~/components/Ak/AkImage.vue'
import { useStore } from '~/store/main'

const store = useStore()
const gtm = useGtm()

defineProps<{
  menu?: strapiNavigationItem[]
}>()

const { status } = useAuth()

const options: { data: { attributes: strapiOptions } } | undefined =
  inject('options')

const transformToLink = (item: strapiNavigationItem) => {
  if (item.related) {
    if (item.related.__contentType === 'api::homepage.homepage') return '/'
    else if (item.related.__contentType === 'api::page.page')
      return `/${item.related.slug}`
    else return item.path
  } else if (item.external || item.type === 'WRAPPER') {
    return item.path
  }
  return undefined
}

const cookieConsent = useCookie('CookieConsent')
const consent = useCookie('consent')

const viewCookie = () => {
  if (window.Cookiebot) {
    window.Cookiebot.renew()
  }
}

onMounted(() => {
  if (!cookieConsent.value) {
    if (window.Cookiebot) Cookiebot.renew()
    if (!consent.value) {
      store.$patch({
        isConsentOpen: true,
      })
    }
  }

  if (!consent.value) {
    store.$patch({
      isConsentOpen: true,
    })
  }
})

const clickSocial = (social) => {
  gtm.trackEvent({
    event: 'socialNetwork',
    action: 'click on social icon',
    value: `User click on social icon: ${social.link}`,
  })
  window.open(social.link, '_blank')
}
</script>

<template>
  <footer v-if="options" class="bg-primary relative z-30 py-20">
    <UContainer>
      <AkRow class="mt-20">
        <template v-if="menu">
          <AkCol data-test="menu" cols-lg="fill" :cols="12">
            <a
              v-if="options.data.attributes.eurazeo_url"
              target="_blank"
              :href="options.data.attributes.eurazeo_url"
            >
              <AkImage
                :image="options.data.attributes.logo_footer"
                class="mb-16 h-50"
              />
            </a>
            <div
              v-if="options.data.attributes.socials"
              class="mb-22 mt-20 flex gap-20"
            >
              <a
                v-for="social in options.data.attributes.socials"
                :key="social.id"
                :href="social.link"
                target="_blank"
                @click.prevent="clickSocial(social)"
              >
                <AkImage v-if="social.icon" :image="social.icon" class="w-20" />
              </a>
            </div>
            <p class="mb-8 text-p4 text-white opacity-70">
              &copy; {{ new Date().getFullYear() }}
              {{ $t('layout.footer.copyright') }}
            </p>
            <p
              v-if="options.data.attributes.address"
              class="mb-8 text-p4 text-white opacity-70"
            >
              {{ options.data.attributes.address }}
            </p>
            <p class="text-p4 text-white opacity-70">
              {{ $t('layout.footer.legal') }}
            </p>
          </AkCol>
          <AkCol
            v-for="item in menu"
            :key="item.id"
            data-test="menu"
            cols-lg="fill"
            :cols="12"
            class="flex lg:justify-center"
          >
            <div>
              <p class="mb-26 text-p2 uppercase text-white">
                {{ item.title }}
              </p>
              <ul v-if="item.items" class="flex flex-col gap-y-26">
                <li
                  v-for="subItem in item.items"
                  :key="subItem.id"
                  class="text-p2 font-semibold"
                >
                  <div
                    v-if="
                      subItem.type === 'WRAPPER' &&
                      subItem.path &&
                      subItem.path.indexOf('cookies')
                    "
                    class="cursor-pointer text-white opacity-70"
                    @click="viewCookie"
                  >
                    {{ subItem.title }}
                  </div>
                  <a
                    v-else-if="subItem.type === 'EXTERNAL'"
                    class="text-white opacity-70"
                    :href="transformToLink(subItem)"
                    :target="subItem.newTab ? '_blank' : undefined"
                  >
                    {{ subItem.title }}
                  </a>
                  <nuxt-link
                    v-else
                    class="text-white opacity-70"
                    :to="transformToLink(subItem)"
                    :target="subItem.newTab ? '_blank' : undefined"
                  >
                    {{ subItem.title }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </AkCol>
        </template>
        <AkCol
          v-if="
            options.data.attributes.phone_number ||
            options.data.attributes.email
          "
          :cols="12"
          cols-lg="fill"
          class="flex lg:justify-center"
        >
          <div>
            <p class="mb-26 font-medium uppercase text-white">
              {{ $t('layout.footer.contact_title') }}
            </p>
            <div class="flex flex-col gap-y-26 text-p2 font-semibold">
              <div v-if="status === 'authenticated'" class="flex items-center">
                <AkImage
                  v-if="options.data.attributes.contact_image"
                  class="mr-8 h-34 w-34 rounded-full object-cover"
                  :image="options.data.attributes.contact_image"
                />
                <div class="flex-1">
                  <p
                    v-if="options.data.attributes.contact_name"
                    class="text-white opacity-70"
                  >
                    {{ options.data.attributes.contact_name }}
                  </p>
                  <p class="text-p3 text-white opacity-70">
                    {{ $t('layout.footer.contact_user') }}
                  </p>
                </div>
              </div>
              <p v-else class="text-white opacity-70">
                {{ $t('layout.footer.contact') }}
              </p>
              <div
                v-if="
                  options.data.attributes.phone_label_login &&
                  status === 'authenticated'
                "
                class="flex items-center"
              >
                <UIcon
                  class="mr-8 h-24 w-24 text-[#0081FE]"
                  name="i-fa6-solid-phone"
                />
                <a
                  class="flex-1 text-white opacity-70"
                  :href="`tel:${options.data.attributes.phone_number_login}`"
                  >{{ options.data.attributes.phone_label_login }}</a
                >
              </div>
              <div
                v-else-if="options.data.attributes.phone_label"
                class="flex items-center"
              >
                <UIcon
                  class="mr-8 h-24 w-24 text-[#0081FE]"
                  name="i-fa6-solid-phone"
                />
                <a
                  class="flex-1 text-white opacity-70"
                  :href="`tel:${options.data.attributes.phone_number}`"
                  >{{ options.data.attributes.phone_label }}</a
                >
              </div>
              <div
                v-if="
                  options.data.attributes.email_login &&
                  status === 'authenticated'
                "
                class="flex items-center"
              >
                <UIcon
                  class="mr-8 h-24 w-24 text-[#0081FE]"
                  name="i-fa6-solid-envelope"
                />
                <a
                  class="flex-1 text-white opacity-70"
                  :href="`mailto:${options.data.attributes.email_login}`"
                  >{{ options.data.attributes.email_login }}</a
                >
              </div>
              <div
                v-else-if="options.data.attributes.email"
                class="flex items-center"
              >
                <UIcon
                  class="mr-8 h-24 w-24 text-[#0081FE]"
                  name="i-fa6-solid-envelope"
                />
                <a
                  class="flex-1 text-white opacity-70"
                  :href="`mailto:${options.data.attributes.email}`"
                  >{{ options.data.attributes.email }}</a
                >
              </div>
            </div>
          </div>
        </AkCol>
      </AkRow>
    </UContainer>
  </footer>
</template>
