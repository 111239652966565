import { buildBody } from '~/utils/dto/partner-user'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { data: user, getSession } = useAuth()

  // Stop l'utilisateur n'est pas connecté ou si ce n'est pas une partenaire
  if (user.value === null || !user.value.user.id.startsWith('ptu')) {
    return
  }

  // Si c'est un utilisateur d'un partenaire parent
  if (!user.value.user._user_child) {
    if (to.name.endsWith('PartnerOnboardingSelectPartner')) {
      return navigateTo('/')
    }

    return
  }

  const hasParentPartner =
    !('parent' in user.value.user.partner) ||
    user.value.user.partner.parent === null

  if (hasParentPartner) {
    // Si l'utilisateur est sur un partenaire parent et qu'il n'y a qu'un enfant
    // Alors l'affecte à cet enfant
    if (user.value.user.partner.childs.length === 1) {
      await useAuthPartnerUserUpdateAsyncFetch(
        buildBody(user.value.user, {
          id_partner: user.value.user.partner.childs[0].id,
        })
      )

      // Permet de rafraichir la session
      await getSession()

      // Ne pas mettre de navigateTo au risque de créer une boucle infinie
      return
    }

    if (!to.name.endsWith('PartnerOnboardingSelectPartner')) {
      return navigateTo({ name: 'frPartnerOnboardingSelectPartner' })
    }
  } else if (to.name.endsWith('PartnerOnboardingSelectPartner')) {
    return navigateTo('/')
  }
})
