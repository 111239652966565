<script setup lang="ts">
import { getLogoutUrl } from '~/utils/auth0/partner/methods'
import { buildBody } from '~/utils/dto/partner-user'

const { data: user, signOut, getSession } = useAuth()
const toast = useToast()

const parentPartner = ref({})

parentPartner.value = await useGetParentPartnerAsyncFetch(user.value.user)

// Enfants sélectionnés par l'utilisateur

const selectedUserChildPartners = ref([])

async function loadSelectedUserChildPartners() {
  const { data, error } = await useAuthSelectedUserChildPartnersAsyncFetch()

  if (!error.value) {
    selectedUserChildPartners.value = data.value
  }
}

await loadSelectedUserChildPartners()

watch(
  () => user.value.user._partner_child_id_authorized_array,
  async () => {
    await loadSelectedUserChildPartners()
  }
)

const showPartners = computed(() => {
  return (
    user.value.user._user_child &&
    user.value.user._partner_child_id_authorized_array.length > 1
  )
})

function logout() {
  // Ne pas utiliser await car provoque un petit laps de temps où les données affichées de l'utilisateur authentifié sont retirées
  signOut({ callbackUrl: getLogoutUrl() })
}

async function changePartner(partner) {
  if (partner.id === user.value.user.partner.id || !showPartners) {
    return
  }

  const { data } = await useAuthPartnerUserUpdateAsyncFetch(
    buildBody(user.value.user, {
      id_partner: partner.id,
    })
  )

  if (data.value.status) {
    if (data.value.status === 'success') {
      // Permet de rafraichir la session
      await getSession()
    } else if (data.value.status === 'error') {
      toast.add({
        title: data.value.messages[0],
        color: 'red',
        timeout: 6000,
      })
    }
  }
}

const items = [
  [
    {
      slot: 'content',
    },
  ],
]

const button = ref()

const closeSwitch = () => {
  button.value.click()
}
</script>

<template>
  <div>
    <UDropdown
      :items="items"
      :ui="{
        width: 'w-[300px]',
        wrapper: 'account-switch',
        ring: 'ring-0',
        base: 'rounded-6',
        item: {
          base: 'text-left hover:bg-white cursor-default',
          padding: 'p-0',
        },
      }"
    >
      <div ref="button" class="account-switch__button">
        <div class="flex items-center">
          <AuthLogo
            class="max-lg:hidden"
            color="black"
            :logo="user.user.partner.logo"
            :name="user.user.partner.name"
          />
          <div>
            <p class="text-p3 font-semibold">{{ user.user.partner.name }}</p>
            <p v-if="user.user._user_child" class="text-p5">
              {{ parentPartner.name }}
            </p>
          </div>
        </div>
        <UIcon
          class="dropdown__chevron ml-42"
          name="i-fa6-solid-chevron-down"
        />
      </div>

      <template #content="{ item }">
        <div class="w-full" @click.stop>
          <div v-if="showPartners" class="border-b border-grey px-24 py-16">
            <div class="mb-8 text-p4 font-semibold text-grey">
              {{ $t('account.team.current_team') }}
            </div>
            <div class="flex items-center">
              <AuthLogo
                :logo="user.user.partner.logo"
                :name="user.user.partner.name"
              />
              <div>
                <p class="text-primary text-p3 font-semibold">
                  {{ user.user.partner.name }}
                </p>
                <p class="text-p5 text-grey">
                  {{ parentPartner.name }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="showPartners" class="border-b border-grey px-24 py-16">
            <div class="mb-8 text-p4 font-semibold text-grey">
              {{ $t('account.team.sub_teams') }}
            </div>
            <div
              v-for="childPartner in selectedUserChildPartners"
              :key="childPartner.id"
              :class="{
                'bg-blue-200': childPartner.id === user.user.partner.id,
                'pointer-events-none': childPartner.id === user.user.partner.id,
                'cursor-pointer': childPartner.id !== user.user.partner.id,
              }"
              class="rounded-4 p-8"
              @click="changePartner(childPartner)"
            >
              <div class="flex items-center">
                <AuthLogo :logo="childPartner.logo" :name="childPartner.name" />
                <div>
                  <p class="text-primary text-p3 font-semibold">
                    {{ childPartner.name }}
                  </p>
                  <p class="text-p5 text-grey">
                    {{ parentPartner.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="px-24 py-16">
            <nuxt-link
              class="flex items-center text-p4 text-grey"
              :to="{ name: 'accountProfil' }"
              @click="closeSwitch"
            >
              <UIcon class="mr-8 h-20 w-20" name="i-fa6-solid-gear" />
              {{ $t('account.account_profile') }}
            </nuxt-link>

            <button
              class="mt-24 flex items-center text-p3 font-semibold text-tertiary-red"
              @click="logout"
            >
              <UIcon
                class="mr-8 h-20 w-20"
                name="i-fa6-solid-right-from-bracket"
              />
              {{ $t('account.layout.menu.logout') }}
            </button>
          </div>
        </div>
      </template>
    </UDropdown>
  </div>
</template>

<style lang="scss" scoped>
.account-switch {
  :deep(div[role='button']) {
    @apply rounded-4 px-16 py-8;
  }

  &[data-headlessui-state='open'] > :deep(div[role='button']) {
    @apply bg-white bg-opacity-10;
  }

  &__button {
    @apply flex items-center justify-between text-white;
  }
}
</style>
