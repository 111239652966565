<script setup lang="ts">
import { getLogoutUrl } from '~/utils/auth0/investor/methods'

const { data: user, signOut } = useAuth()

const logout = () => {
  signOut({ callbackUrl: getLogoutUrl() })
}

const items = [
  [
    {
      slot: 'content',
    },
  ],
]

const button = ref()

const closeSwitch = () => {
  button.value.click()
}
</script>

<template>
  <div>
    <UDropdown
      :items="items"
      :ui="{
        width: 'w-[300px]',
        wrapper: 'account',
        ring: 'ring-0',
        base: 'rounded-6',
        item: {
          base: 'text-left hover:bg-white cursor-default',
          padding: 'p-0',
        },
      }"
    >
      <div ref="button" class="account__button">
        <div class="flex items-center">
          <div>
            <p class="text-p3 font-semibold">{{ user.user.full_name }}</p>
          </div>
        </div>
        <UIcon
          class="dropdown__chevron ml-42"
          name="i-fa6-solid-chevron-down"
        />
      </div>
      <template #content="{ item }">
        <div class="w-full" @click.stop>
          <div class="px-24 py-16">
            <nuxt-link
              class="flex items-center text-p4 text-grey"
              :to="{ name: 'accountProfil' }"
              @click="closeSwitch"
            >
              <UIcon class="mr-8 h-20 w-20" name="i-fa6-solid-gear" />
              {{ $t('account.account_profile') }}
            </nuxt-link>
            <button
              class="mt-24 flex items-center text-p3 font-semibold text-tertiary-red"
              @click="logout"
            >
              <UIcon
                class="mr-8 h-20 w-20"
                name="i-fa6-solid-right-from-bracket"
              />
              {{ $t('account.layout.menu.logout') }}
            </button>
          </div>
        </div>
      </template>
    </UDropdown>
  </div>
</template>

<style lang="scss" scoped>
.account {
  :deep(div[role='button']) {
    @apply rounded-4 px-16 py-8;
  }

  &[data-headlessui-state='open'] > :deep(div[role='button']) {
    @apply bg-white bg-opacity-10;
  }

  &__button {
    @apply flex items-center justify-between text-white;
  }
}
</style>
