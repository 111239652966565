import _ from 'lodash'
// import nuxtStorage from 'nuxt-storage'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig()

  const maintenanceActive = config.public.maintenance.active
  const maintenanceSecret = config.public.maintenance.secret

  const toQuerySecret = _.get(to.query, 'secret')

  if (toQuerySecret === maintenanceSecret) {
    // nuxtStorage.localStorage.setData('bypass-maintenance', true, 20)
  }

  if (maintenanceActive) {
    if (
      // !nuxtStorage.localStorage.getData('bypass-maintenance') &&
      to.name !== 'maintenance'
    ) {
      return navigateTo({ name: 'maintenance' })
    }
  } else if (to.name === 'maintenance') {
    return navigateTo('/')
  }
})
