import _ from 'lodash'
import { getLogoutUrl as getPartnerUserLogoutUrl } from '~/utils/auth0/partner/methods'
import { getLogoutUrl as getInvestorContactLogoutUrl } from '~/utils/auth0/investor/methods'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { data: user, signOut } = useAuth()

  if (!_.has(user.value, 'error')) {
    return
  }

  /*
   * Déconnecte l'utilisateur s'il est introuvable
   * Utile lorsqu'il est supprimé d'investhub
   */
  if (user.value.error === 'partner_user_not_found') {
    await signOut({ callbackUrl: getPartnerUserLogoutUrl() })
  } else if (user.value.error === 'investor_contact_not_found') {
    await signOut({ callbackUrl: getInvestorContactLogoutUrl() })
  }
})
