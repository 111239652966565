import {
  useApiAsyncFetch,
  useAuthApiAsyncFetch,
} from '@/composables/proxyApiUseFetch'

// PUBLIC

export async function usePartnerUserExistsAsyncFetch(email: String) {
  const urlSearchParams = new URLSearchParams({
    email,
  })

  return await useApiAsyncFetch(`/partner-users/exists?${urlSearchParams}`)
}

export async function usePartnerUserDomainExistsAsyncFetch(domain: string) {
  const urlSearchParams = new URLSearchParams({
    domain,
  })

  return await useApiAsyncFetch(`/partner-users/domain?${urlSearchParams}`)
}

export async function usePartnerUserCreateAsyncFetch(body: Object) {
  return await useApiAsyncFetch(`/auth0/partner-users/create`, {
    method: 'POST',
    body: {
      ...{
        email: body.email,
        password: body.password,
      },
      ...{
        user_metadata: body,
      },
    },
  })
}

export async function useGetPartnerUserFieldsAsyncFetch() {
  return await useApiAsyncFetch('/partner-users/fields')
}

// AUTH

export async function useAuthPartnerUserUpdateAsyncFetch(body: Object) {
  return await useAuthApiAsyncFetch(`/auth/partner-users/update`, {
    method: 'POST',
    body,
  })
}

export function useAuthPartnerUserUpdateFetch(body: Object) {
  return useAuthApiFetch(`/auth/partner-users/update`, {
    method: 'POST',
    body,
  })
}

export async function useAuthSendPartnerUserSMSInvitationAsyncFetch(
  phone: String,
  partnerName: String,
  message: String
) {
  return await useAuthApiAsyncFetch(`/auth/partner-users/sms-invitation`, {
    method: 'POST',
    body: {
      phone,
      partnerName,
      message,
    },
  })
}

export async function useAuthSendPartnerUserEmailInvitationAsyncFetch(
  email: String,
  byUser: Object
) {
  const parentPartner = await useGetParentPartnerAsyncFetch(byUser)

  return await useAuthApiAsyncFetch(`/auth/partner-users/email-invitation`, {
    method: 'POST',
    body: {
      email,
      partnerName: parentPartner.name,
      username: `${byUser.firstname} ${byUser.lastname}`,
    },
  })
}

export async function useAuthPartnerUserLogsAsyncFetch() {
  return await useAuthApiAsyncFetch(`/auth/partner-users/auth-logs`)
}

export async function useGetParentPartnerAsyncFetch(partnerUser: Object) {
  // Si c'est un utilisateur partenaire parent
  if (!partnerUser._user_child) {
    return partnerUser.partner
  }

  if (partnerUser.partner.parent) {
    const { data: fetchParentPartner, error: fetchParentPartnerError } =
      await useAuthPartnerAsyncFetch(partnerUser.partner.parent)

    if (!fetchParentPartnerError.value) {
      return fetchParentPartner.value
    }
  }

  return {}
}

export async function useAuthSelectedUserChildPartnersAsyncFetch() {
  return await useAuthApiAsyncFetch(
    `/auth/partner-users/selected-user-child-partners`
  )
}
