import { getLogoutUrl } from '~/utils/auth0/user/methods'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { data: user, signOut, status } = useAuth()

  if (
    status.value !== 'authenticated' ||
    !user.value.user.is_investor_contact
  ) {
    return
  }

  const investor = user.value.user.investor

  if (investor._matching_status && investor._matching_status === 'denied') {
    await signOut({ callbackUrl: getLogoutUrl(user.value.user) })
  }
})
