<script setup lang="ts">
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import { definePageMeta } from '#imports'
import type {
  strapiNavigationItem,
  strapiOptions,
} from '~/interfaces/strapi.interface'

const { find, findOne } = useStrapi()
const { locale } = useI18n()

const props = defineProps<{
  error: {
    statusCode: number
    statusMessage?: string
  }
}>()

const router = useRouter()

definePageMeta({
  layout: 'default',
})

const handleError = () => clearError({ redirect: '/' })

const topMenu = ref()
const headerMenu = ref()
const footerMenu = ref()

const topNavigation =
  locale.value === 'fr'
    ? 'navigation/render/top-navigation?type=TREE'
    : 'navigation/render/top-navigation-en?type=TREE'

const { data: dataTop } = await useAsyncData('topNavigation', () =>
  find<strapiNavigationItem[]>(topNavigation)
)

if (dataTop.value) topMenu.value = dataTop.value
else topMenu.value = []

let headerNavigation: string

const { status } = useAuth()
if (locale.value === 'fr') {
  if (status.value === 'authenticated') {
    headerNavigation = 'navigation/render/header-navigation-connected?type=TREE'
  } else {
    headerNavigation = 'navigation/render/header-navigation?type=TREE'
  }
} else if (status.value === 'authenticated') {
  headerNavigation =
    'navigation/render/header-navigation-connected-en?type=TREE'
} else {
  headerNavigation = 'navigation/render/header-navigation-en?type=TREE'
}

const { data: dataHeader } = await useAsyncData('headerNavigation', () =>
  find<strapiNavigationItem[]>(headerNavigation)
)

if (dataHeader.value) headerMenu.value = dataHeader.value
else headerMenu.value = []

const footerNavigation =
  locale.value === 'fr'
    ? 'navigation/render/footer-navigation?type=TREE'
    : 'navigation/render/footer-navigation-en?type=TREE'

const { data: dataFooter } = await useAsyncData('footerNavigation', () =>
  find<strapiNavigationItem[]>(footerNavigation)
)

if (dataFooter.value) footerMenu.value = dataFooter.value
else footerMenu.value = []

// options
const { data: options } = await useAsyncData('options', () =>
  findOne<strapiOptions>('option', {
    populate:
      'url_match, socials, socials.icon, logo_header, logo_footer, contact_image, logo_blue, image_register, consent_image',
    locale: locale.value as StrapiLocale,
  })
)

provide('options', options)
</script>

<template>
  <div class="layout-error bg-primary">
    <AkHeader :menu="[...headerMenu]" :top="[...topMenu]" />
    <div
      class="min-h-[calc(100dvh_-_83.5px] flex flex-col items-center justify-center py-20"
    >
      <div class="error-img"></div>
      <h2 class="mt-20 text-42 font-bold text-white">
        {{ $t('error.title') }} {{ error.statusCode }}
      </h2>
      <div class="mt-10 text-24 text-white">
        <p v-if="error.statusCode === 404">{{ $t('error.404') }}</p>
        <p v-else-if="error.statusCode === 403">{{ $t('error.403') }}</p>
        <p v-else>{{ $t('error.default') }}</p>
      </div>
      <UButton
        variant="outline"
        color="transparent"
        class="mt-20"
        :label="$t('error.button')"
        @click="handleError"
      />
    </div>
    <AkFooter class="mt-80" :menu="[...footerMenu]" />
  </div>
</template>

<style lang="scss">
body {
  @apply font-inter text-[1rem] leading-normal text-black;
}

span[class^='i-fa6-'] {
  mask-size: 100% !important;
  mask-position: center !important;
}

.layout-error {
  @media (min-width: 1280px) {
    min-height: calc(100vh - 82px);
    margin-top: 82px;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  footer {
    margin-top: 1.25rem !important;
  }
}

#CookiebotWidget {
  display: none;
}

.custom-checkbox {
  label {
    margin-top: 2px;
  }
}

.error-img {
  position: relative;
  width: 200px;
  height: 200px;
  &::before {
    content: '';
    position: absolute;
    inset: 0 auto auto 0;
    background-image: url('@/assets/images/error.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
}
</style>
