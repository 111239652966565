import { useAuthApiAsyncFetch } from '@/composables/proxyApiUseFetch'

// AUTH

export async function useAuthPartnerFundDocumentsSearchAsyncFetch(
  fundId: string
) {
  return await useAuthApiAsyncFetch(
    `/auth/partners/funds/${fundId}/documents/search`
  )
}

export async function useAuthPartnerAsyncFetch(partnerId: string) {
  return await useAuthApiAsyncFetch(`/auth/partners/${partnerId}`)
}

export async function useAuthPartnerFundsAsyncFetch(queryParams: Object) {
  return await useAuthApiAsyncFetch(`/auth/partners/funds`, {
    params: queryParams,
  })
}
