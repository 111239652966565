import { getLogoutUrl as getPartnerLogoutUrl } from '~/utils/auth0/partner/methods'
import { getLogoutUrl as getInvestorLogoutUrl } from '~/utils/auth0/investor/methods'

export function getLogoutUrl(user: Object, returnTo?: string) {
  if (user.is_partner_user) {
    return getPartnerLogoutUrl(returnTo)
  }

  if (user.is_investor_contact) {
    return getInvestorLogoutUrl(returnTo)
  }

  return '/'
}

export function getAuth0ApplicationIssuer(user: Object) {
  const config = useRuntimeConfig()

  if (user.is_partner_user) {
    return config.public.eurazeo.auth0.partner.issuer
  }

  if (user.is_investor_contact) {
    return config.public.eurazeo.auth0.investor.issuer
  }

  return '/'
}
