import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state: () => {
    return {
      isMenuAccountOpen: false,
      isConsentOpen: false,
    }
  },
})
