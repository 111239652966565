import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45prevent_45auth0_45redirect_45global from "/app/middleware/00.prevent-auth0-redirect.global.ts";
import _01_45check_45user_45global from "/app/middleware/01.check-user.global.ts";
import _02_45maintenance_45global from "/app/middleware/02.maintenance.global.ts";
import _03_45check_45investor_45matching_45status_45global from "/app/middleware/03.check-investor-matching-status.global.ts";
import _04_45partner_45onboarding_45global from "/app/middleware/04.partner-onboarding.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45prevent_45auth0_45redirect_45global,
  _01_45check_45user_45global,
  _02_45maintenance_45global,
  _03_45check_45investor_45matching_45status_45global,
  _04_45partner_45onboarding_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}