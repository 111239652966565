export async function useApiAsyncFetch(url: string, options?: object) {
  const config = useRuntimeConfig()

  const opts = {
    ...options,
    key: url,
    baseURL: config.public.proxy.apiBaseUrl,
    watch: false, // Important : évite de re-soumettre à chaque fois que le body change
  }

  const { pending, data, error } = await useFetch(url, opts)

  return { pending, data, error }
}

export function useApiFetch(url: string, options?: object) {
  const config = useRuntimeConfig()

  const opts = {
    ...options,
    key: url,
    baseURL: config.public.proxy.apiBaseUrl,
    watch: false, // Important : évite de re-soumettre à chaque fois que le body change
  }

  const { pending, data, error } = useFetch(url, opts)

  return { pending, data, error }
}

export async function useAuthApiAsyncFetch(url: string, options?: object) {
  const config = useRuntimeConfig()
  const { data: authData } = useAuth()

  const opts = {
    ...options,
    key: url,
    baseURL: config.public.proxy.apiBaseUrl,
    headers: {
      Authorization: authData.value.proxyJwt,
    },
    watch: false, // Important : évite de re-soumettre à chaque fois que le body change
  }

  const { pending, data, error } = await useFetch(url, opts)

  return { pending, data, error }
}

export function useAuthApiFetch(url: string, options?: object) {
  const config = useRuntimeConfig()
  const { data: authData } = useAuth()

  const opts = {
    ...options,
    key: url,
    baseURL: config.public.proxy.apiBaseUrl,
    headers: {
      Authorization: authData.value.proxyJwt,
    },
    watch: false, // Important : évite de re-soumettre à chaque fois que le body change
  }

  const { pending, data, error } = useFetch(url, opts)

  return { pending, data, error }
}
